:root {
    --reach-tooltip: 1;
    --marketist-link-decoration: underline;
    --marketist-link-hover-decoration: none;
    --marketist-link-color: var(--display-onlight-color-blue);
}

html,
body {
    min-block-size: 100vh;
    min-block-size: 100dvh;
}

html :target {
        scroll-margin-top: var(--space-96);
    }

@media (prefers-reduced-motion: no-preference) {

html {
        scroll-behavior: smooth
}
    }

_::-webkit-full-page-media,
_:future,
:root .display-none-safari-only {
    display: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
button,
textarea {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
    border-collapse: separate;
    border-spacing: 0;
}
caption,
th,
td {
    text-align: left;
    font-weight: normal;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
}
blockquote,
q {
    quotes: '' '';
}

button {
    text-align: left;
}

a:hover {
    text-decoration: underline;
}

.fonts_injectFonts__M01y5 {
    --font-family-body: var(--next-font-family-body);
    --font-family-sans: var(--next-font-family-sans);
    --font-family-heading: var(--next-font-family-sans);
    --font-family-serif: var(--next-font-family-serif);
    --font-family-handwritten: var(--next-font-family-handwritten);
    --font-family-handwritten-base: var(--next-font-family-handwritten-base);
}

.app_appContainer__ru8zT {
    display: flex;
    flex-direction: column;
    min-block-size: 100vh;
    min-block-size: 100dvh;
    margin-inline: auto;
}

    .app_appContainer__ru8zT *::-moz-selection {
        background: #ffa;
    }

    .app_appContainer__ru8zT *::selection {
        background: #ffa;
    }

.app_pageBackground__WCTyD {
    background-color: var(--page-background, var(--composition-background-neutral, #fdf4eb));
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/027886536771db80-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/094e47203f8b5ede-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/f488fd38492124c8-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/0463d420c2acccca-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/9cff5d163b440164-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/df62e5cbbb783fc3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/d873d0d7738ce615-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_8cb8ff';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://todoist.b-cdn.net/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_8cb8ff';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_8cb8ff {font-family: '__Inter_8cb8ff', '__Inter_Fallback_8cb8ff', Helvetica Neue, Helvetica, Arial, sans-serif
}.__variable_8cb8ff {--next-font-family-body: '__Inter_8cb8ff', '__Inter_Fallback_8cb8ff', Helvetica Neue, Helvetica, Arial, sans-serif
}

@font-face {
font-family: '__graphik_639b95';
src: url(https://todoist.b-cdn.net/_next/static/media/ef4deda2fe4d8408-s.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__graphik_639b95';
src: url(https://todoist.b-cdn.net/_next/static/media/5281648b6f880411-s.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__graphik_639b95';
src: url(https://todoist.b-cdn.net/_next/static/media/f5a0076dcc331c24-s.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__graphik_Fallback_639b95';src: local("Arial");ascent-override: 75.32%;descent-override: 16.76%;line-gap-override: 0.00%;size-adjust: 108.61%
}.__className_639b95 {font-family: '__graphik_639b95', '__graphik_Fallback_639b95', Helvetica Neue, Helvetica, Arial, sans-serif
}.__variable_639b95 {--next-font-family-sans: '__graphik_639b95', '__graphik_Fallback_639b95', Helvetica Neue, Helvetica, Arial, sans-serif
}

@font-face {
font-family: '__caecilia_1ee377';
src: url(https://todoist.b-cdn.net/_next/static/media/4d4476403ac4af54-s.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__caecilia_1ee377';
src: url(https://todoist.b-cdn.net/_next/static/media/10022f49914b6548-s.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__caecilia_1ee377';
src: url(https://todoist.b-cdn.net/_next/static/media/b32f5feebb4cbf48-s.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__caecilia_1ee377';
src: url(https://todoist.b-cdn.net/_next/static/media/b7753009d26ef1cd-s.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__caecilia_Fallback_1ee377';src: local("Arial");ascent-override: 82.56%;descent-override: 24.07%;line-gap-override: 0.00%;size-adjust: 112.15%
}.__className_1ee377 {font-family: '__caecilia_1ee377', '__caecilia_Fallback_1ee377', serif
}.__variable_1ee377 {--next-font-family-serif: '__caecilia_1ee377', '__caecilia_Fallback_1ee377', serif
}

@font-face {
font-family: '__espa_427886';
src: url(https://todoist.b-cdn.net/_next/static/media/e130e99007defafc-s.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__espa_Fallback_427886';src: local("Arial");ascent-override: 142.31%;descent-override: 68.77%;line-gap-override: 7.46%;size-adjust: 67.03%
}.__className_427886 {font-family: '__espa_427886', '__espa_Fallback_427886', Helvetica Neue, Helvetica, Arial, sans-serif;font-weight: 400;font-style: normal
}.__variable_427886 {--next-font-family-handwritten: '__espa_427886', '__espa_Fallback_427886', Helvetica Neue, Helvetica, Arial, sans-serif
}

